<div class="modal-header">
	<h4 class="modal-title"><span class="fa fa-percent mr-2 text-center"></span> Modify payout rate</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" tabindex="-1">
		&times;
	</button>
</div>

<div class="modal-body" style="font-size: larger">
	<div class="form-check">
		<input class="form-check-input" type="radio" name="dayRate" id="dayRate" value="DAY" [(ngModel)]="rate">
		<label class="form-check-label" for="dayRate">
			<i class="fa fa-fw fa-sun-o"></i> Day rate
		</label>
	</div>
	<div class="form-check mt-2">
		<input class="form-check-input" type="radio" name="nightRate" id="nightRate" value="NIGHT" [(ngModel)]="rate">
		<label class="form-check-label" for="nightRate">
			<i class="fa fa-fw  fa-moon-o"></i> Night rate
		</label>
	</div>
	<div class="form-check mt-2">
		<input class="form-check-input" type="radio" name="splitRate" id="splitRate" value="SPLIT" [(ngModel)]="rate">
		<label class="form-check-label" for="splitRate">
			<i class="fa fa-fw fa-sun-o"></i>/<i class="fa fa-fw  fa-moon-o"></i>
			Split between day & night
		</label>
	</div>
	<div class="form-check mt-2">
		<input class="form-check-input" type="radio" name="customRate" id="customRate" value="CUSTOM" [(ngModel)]="rate">
		<label class="form-check-label" for="customRate">
			<i class="fa fa-fw fa-percent"></i>
			Custom rate
		</label>
	</div>

	<div *ngIf="rate === 'CUSTOM'">
		<div class="row justify-content-center">
			<div class="text-center col-12">
				<i class="fa fa-fw fa-percent"></i> Percentage
			</div>
			<div class="col-4">
				<input type="number" class="form-control text-center" min="1"
					   [(ngModel)]="customRate"
					   min="0"
					   max="100"
				>
			</div>
		</div>
	</div>

	<div *ngIf="rate === 'SPLIT'">
		<br/>
		<div class="row justify-content-center">
			<div class="text-center col-12">
				<i class="fa fa-fw fa-sun-o"></i> Session start time
			</div>
			<div class="col-auto">
				<ngb-timepicker [(ngModel)]="startTime" id="startTime"></ngb-timepicker>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="text-center col-12">
				<i class="fa fa-fw  fa-moon-o"></i> Session end time
			</div>
			<div class="col-auto">
				<ngb-timepicker [(ngModel)]="endTime" id="endTime"></ngb-timepicker>
			</div>
		</div>
	</div>

</div>

<div class="modal-footer">
	<button type="button" class="btn btn-danger mr-auto" (click)="activeModal.close()">Cancel</button>
	<label class="mr-auto"></label>
	<button type="button" class="btn btn-success" [disabled]="!canSave()" (click)="save()">Save
	</button>
</div>
