import {Component} from '@angular/core';
import {NgbActiveModal, NgbDate, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateUtil} from '../../../../core/util/date.util';
import {Tattoo} from '../../../../model/domain/tattoo';
import {TattooService} from '../../../../core/service/tattoo.service';

@Component({
	selector: 'app-modify-share',
	templateUrl: './modify-share-tattoo.component.html',
	styleUrls: ['./modify-share-tattoo.component.scss']
})
export class ModifyShareTattooComponent {
	tattoo: Tattoo;
	loading = false;
	form = {
		percentage: 0
	};

	rate: string;
	originalRate: string;
	date: NgbDate;
	startTime: NgbTimeStruct;
	endTime: NgbTimeStruct;
	customRate: number;

	constructor(
		public activeModal: NgbActiveModal,
		private tattooService: TattooService
	) {}

	setTattoo(tattoo: Tattoo, rate: string): void {
		this.rate = rate;
		this.originalRate = rate;

		this.startTime = DateUtil.getNbgTimeFromNumber(tattoo.sessionStart);
		this.endTime = DateUtil.getNbgTimeFromNumber(tattoo.sessionEnd);

		this.date = DateUtil.getNbgDateFromNumber(tattoo.sessionStart);

		this.tattoo = tattoo;
		this.form = {
			percentage: tattoo.artistSharePerc
		};
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}

		if (this.rate === 'SPLIT') {
			if (this.startTime.hour > 18) {
				return false;
			}
			if (this.endTime.hour < 19) {
				return false;
			}
		} else if (this.rate !== 'CUSTOM') {
			if (this.originalRate === this.rate) {
				return false;
			}
		}

		return true;
	}

	save(): void {
		this.loading = true;

		// Convert start & end times to timestamps
		const start = DateUtil.getTimestampFromDateTime(this.date, this.startTime);
		const end = DateUtil.getTimestampFromDateTime(this.date, this.endTime);

		this.tattooService.changeTattooRate(this.tattoo, this.rate.toUpperCase(), this.customRate, start, end)
			.subscribe(tattoo => this.activeModal.close(tattoo));
	}
}
